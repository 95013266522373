<template>
  <div class="live">
    <container :gutter="20">
      <div class="banner-slider">
        <el-carousel :interval="2000" arrow="always">
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/96ce54"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/f05654"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>

          <el-carousel-item>
            <img
              width="100%"
              src="https://dummyimage.com/145x145/1bd1a5"
              alt=""
              sizes=""
              srcset=""
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div slot="side" class="side">
        <div class="live-notice">
          <div class="title">48h直播预告</div>
          <div class="time-line">
            <div class="box" v-for="i in 4" :key="i">
              <div class="box-title">IT打工人准点下班指南</div>
              <div class="box-time">今天 17:00</div>
              <span class="dot"></span>
            </div>
          </div>
        </div>
      </div>
    </container>

    <container :gutter="0" :sideWidth="0">
      <div class="last-live-title">最新直播</div>
    </container>

    <container :gutter="20" :sideWidth="410">
      <div class="first-last-poster">
        <img
          width="100%"
          height="100%"
          src="https://dummyimage.com/330x330/c93756"
          alt=""
          srcset=""
        />

        <div class="desc">2021年中国资产管理年会:经济新动能·资管新趋势</div>
      </div>
      <div slot="side" class="live-box-wrap">
        <div class="last-live-box" v-for="i in 4" :key="i">
          <div class="poster">
            <img src="https://dummyimage.com/195x110/c93756" alt="" srcset="" />
          </div>
          <div class="title">
            2021（第十八届）北京互联网大会 • 打造全球数字经济标杆城市
          </div>
        </div>
      </div>
    </container>

    <container :gutter="0" :sideWidth="0">
      <div class="last-live-title">
        全部直播
        <span class="filter-btn">创新</span>
        <span class="filter-btn">科技</span>
        <span class="filter-btn">财经</span>
        <span class="filter-btn">知识</span>
      </div>
    </container>

    <container :gutter="0" :sideWidth="0">
      <div class="live-list-wrap">
        <el-row :gutter="20">
          <el-col :span="6" v-for="i in 16" :key="i">
            <div class="live-box">
              <div class="live-box-hover"></div>
              <div class="live-box-content">
                <div class="poster">
                  <img src="https://dummyimage.com/240x135/a3d900" alt="" />
                </div>
                <div class="title-time">
                  <div class="title">全新第二代GS8 NEDC&WLTC油耗测试</div>
                  <div class="time">
                    <span>开播时间</span>
                    <span>09-17 13:45</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </container>
  </div>
</template>

<script>
import Container from "./Container.vue";

export default {
  components: { Container },
};
</script>

<style lang="scss" scoped>
.live {
  padding: 20px 0;

  .side {
    height: 100%;
  }

  .live-notice {
    height: 100%;
    border: 1px solid #b3d0ff;
    border-radius: 2px;

    .title {
      font-size: 22px;
      padding: 27px 0 27px 26px;
      background: #d7e5ff;
      font-style: italic;
    }

    .time-line {
      margin: 22px 24px 2px;

      .box {
        position: relative;
        padding: 10px;
        border-left: 1px solid #b3d0ff;

        .box-title {
          max-width: 216px;
          font-size: 13px;
          color: #373737;
          text-align: justify;
          font-weight: 700;
          max-height: 40px;
          margin-bottom: 4px;
          line-height: 18px;
          cursor: pointer;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .box-time {
          font-size: 12px;
          color: #4285f4;
          font-weight: 700;
          display: inline-block;
          padding: 2px;
          background: #e6eeff;
          border-radius: 2px;
        }

        .dot {
          position: absolute;
          width: 8px;
          height: 8px;
          background: #acc8ff;
          border: 2px solid #fff;

          transform: rotate(45deg);

          top: 14px;
          left: -4px;
        }
      }
    }
  }

  .banner-slider {
    ::v-deep .el-carousel__container {
      height: 400px;
    }
  }

  .last-live-title {
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: 24px;
    color: #262626;
    font-weight: 700;
  }

  .first-last-poster {
    position: relative;

    height: 330px;
    overflow: hidden;

    &:hover,
    &:active {
      img {
        transform: scale(1.2);
      }
    }

    img {
      transition: all 0.5s;
    }

    .desc {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;

      font-size: 18px;
      font-weight: 700;
      color: #fff;
      padding: 65px 0 20px 20px;

      background: linear-gradient(
        180deg,
        transparent 18%,
        rgba(0, 0, 0, 0.48) 68%,
        rgba(0, 0, 0, 0.62) 92%
      );

      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .last-live-box {
    position: relative;
    cursor: pointer;
    display: inline-block;
    max-height: 155px;
    width: 45%;
    overflow: hidden;

    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 3) {
      margin-top: 10px;
    }

    .title {
      font-size: 14px;
      font-weight: bold;
      min-height: 40px;

      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .filter-btn {
    cursor: pointer;
    font-size: 13px;
    color: #262626;
    font-weight: normal;

    &:hover,
    &:active {
      color: royalblue;
      font-weight: bold;
    }

    &:nth-child(1) {
      margin-left: 20px;
    }

    & + .filter-btn {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 2px solid #ededed;
    }
  }

  .live-list-wrap {
    .live-box {
      position: relative;
      margin-bottom: 25px;

      // .live-box-hover {
      //   z-index: 1;
      //   position: absolute;
      //   top: -10px;
      //   left: -10px;
      //   bottom: -10px;
      //   right: -10px;
      //   // background: #fff;
      //   box-shadow: 0 0 7px 0 rgb(0 0 0 / 15%);
      // }

      .live-box-content {
        .poster {
          width: 100%;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .title-time {
          .title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 9px;
            margin-top: 12px;
          }

          .time {
            padding: 4px 6px;
            display: inline-block;
            font-size: 12px;
            color: #4285f4;
            background: #e6eeff;
            font-weight: bold;

            span + span {
              margin-left: 8px;
              padding-left: 8px;
              border-left: 2px solid #4285f4;
            }
          }
        }
      }
    }
  }
}
</style>